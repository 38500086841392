import { useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";

export interface SearchValues {
  categories: Record<string, string>;
  regions: Record<string, string>;
  offices: Record<string, string>;
  keyword: string;
  seekingContributors: boolean;
}

export interface SearchState extends SearchValues {
  setState: (args: Partial<SearchValues>) => void;
  cleanup: () => void;
  isFilterApplied: () => boolean;
}

const useSearch = (): SearchState => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Helper to safely parse JSON from a URL parameter
  const parseParam = (param: string | null): Record<string, string> => {
    try {
      return param ? JSON.parse(param) : {};
    } catch (error) {
      console.error("Error parsing parameter:", error);
      return {};
    }
  };

  // Create a memoized current state object from URL search params
  const currentState: SearchValues = useMemo(() => {
    return {
      categories: parseParam(searchParams.get("categories")),
      regions: parseParam(searchParams.get("regions")),
      offices: parseParam(searchParams.get("offices")),
      keyword: searchParams.get("keyword") || "",
      seekingContributors: searchParams.get("seekingContributors") === "true",
    };
  }, [searchParams]);

  // Merge new state with the current state and update URL only if something changed
  const setState = useCallback(
    (newState: Partial<SearchValues>) => {
      const mergedState: SearchValues = { ...currentState, ...newState };

      // Compare current and merged state; if identical, do nothing.
      if (JSON.stringify(currentState) === JSON.stringify(mergedState)) {
        return;
      }

      // Prepare new URL params
      const params: Record<string, string> = {};

      if (Object.keys(mergedState.categories).length > 0) {
        params.categories = JSON.stringify(mergedState.categories);
      }
      if (Object.keys(mergedState.regions).length > 0) {
        params.regions = JSON.stringify(mergedState.regions);
      }
      if (Object.keys(mergedState.offices).length > 0) {
        params.offices = JSON.stringify(mergedState.offices);
      }
      if (mergedState.keyword.trim()) {
        params.keyword = mergedState.keyword;
      }
      if (mergedState.seekingContributors) {
        params.seekingContributors = "true";
      }

      if (mergedState.seekingContributors) {
        params.seekingContributors = "true";
      }

      setSearchParams(params);
    },
    [currentState, setSearchParams]
  );

  // Check if any filter is currently applied
  const isFilterApplied = useCallback((): boolean => {
    return (
      Object.keys(currentState.categories).length > 0 ||
      Object.keys(currentState.regions).length > 0 ||
      Object.keys(currentState.offices).length > 0 ||
      currentState.keyword.trim().length > 0 ||
      currentState.seekingContributors === true
    );
  }, [currentState]);

  // Cleanup: clear all search params
  const cleanup = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  return {
    ...currentState,
    setState,
    isFilterApplied,
    cleanup,
  };
};

export { useSearch };
